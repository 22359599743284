import { useEffect, useState } from 'react';
import { ApiModel, apiEnums } from '@nodal/api';
import type { User } from 'api';
import { appPaths, matchingPaths, navigatorPaths, paths } from 'consts/paths';
import { useParams } from 'react-router-dom';
import { settings } from 'settings';

const getPathBy = (
  status: ApiModel.UserStatusEnum,
  role?: ApiModel.UserRoleEnum,
  hasCompletedScreening = false,
) => {
  if (status === apiEnums.UserStatusEnum['Scr+'] && !hasCompletedScreening) {
    return appPaths.paymentService;
  }

  if (
    role === apiEnums.UserRoleEnum.Par &&
    settings.getIpDashboardFeatureEnabled()
  ) {
    return appPaths.dashboard;
  } else if (
    status === apiEnums.UserStatusEnum.Scr ||
    ((status === apiEnums.UserStatusEnum.Dis ||
      status === apiEnums.UserStatusEnum.Dea) &&
      !hasCompletedScreening)
  ) {
    return appPaths.screening;
  } else if (role === apiEnums.UserRoleEnum.Nap) {
    return appPaths.navigator;
  } else {
    return appPaths.matching;
  }
};

const restrictedStatuses = [
  apiEnums.UserStatusEnum['Scr'],
  apiEnums.UserStatusEnum['Dis'],
  apiEnums.UserStatusEnum['Dea'],
];

const restrictedPaths = new Map<User['role'], string[]>([
  [
    apiEnums.UserRoleEnum.Dnr,
    [`${appPaths.navigator}/${navigatorPaths.profile}`, appPaths.dashboard],
  ],
  [
    apiEnums.UserRoleEnum.Par,
    [
      `${appPaths.matching}/${matchingPaths.parents}`,
      `${appPaths.matching}/${matchingPaths.favorites}`,
      `${appPaths.navigator}/${navigatorPaths.profile}`,
    ],
  ],
  [
    apiEnums.UserRoleEnum.Nap,
    [
      `${appPaths.matching}/${matchingPaths.parents}`,
      `${appPaths.matching}/${matchingPaths.favorites}`,
      `${appPaths.matching}/${matchingPaths.matches}`,
      appPaths.dashboard,
    ],
  ],
]);

const getRestrictedPath = (role?: User['role']) => {
  if (!role) {
    return undefined;
  }

  return restrictedPaths.get(role);
};

const hasRestrictedAccess = (
  pathname: string,
  status: ApiModel.UserStatusEnum,
  role?: User['role'],
) => {
  const hasRestrictedStatus = !!restrictedStatuses.find((s) => s === status);

  const hasRestrictedPath = !!getRestrictedPath(role)?.find(
    (path) => path === pathname,
  );

  return hasRestrictedStatus || hasRestrictedPath;
};

type ForceRedirectParams = {
  status?: ApiModel.UserStatusEnum;
  role?: ApiModel.UserRoleEnum;
  hasCompletedScreening?: boolean;
};

export const useForcedRedirect = ({
  status,
  role,
  hasCompletedScreening,
}: ForceRedirectParams) => {
  const [forcedRedirectPath, setForcedRedirectPath] = useState<string>();
  const params = useParams();
  const path = params['*'] || '';

  useEffect(() => {
    if (status) {
      const statusPath = getPathBy(status, role, hasCompletedScreening);

      // NOTE: Currently only 'dnr', 'par' and 'nap' are supported.
      if (
        role !== apiEnums.UserRoleEnum.Dnr &&
        role !== apiEnums.UserRoleEnum.Par &&
        role !== apiEnums.UserRoleEnum.Nap
      ) {
        setForcedRedirectPath(paths.logout);
        return;
      }

      // NOTE: Autoredirect, usually after sign-in.
      if (statusPath && !path) {
        setForcedRedirectPath(statusPath);
        return;
      }

      // NOTE: Always allow parents to redirect to dashboard when the ip dashboard feature is enabled
      if (
        path === appPaths.dashboard &&
        role === apiEnums.UserRoleEnum.Par &&
        settings.getIpDashboardFeatureEnabled()
      ) {
        return;
      }

      if (statusPath && hasRestrictedAccess(path, status, role)) {
        // NOTE: Redirect due to restriction.
        setForcedRedirectPath(statusPath);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, status, role]);

  return forcedRedirectPath;
};
